$icon-font-path: "../fonts/bootstrap/";
$brand-success: #bed600;
$brand-primary: #00a9e0;
$border-radius-base: 0px;
$border-radius-large: 0px;
$border-radius-small: 0px;
$navbar-height: 120px;
$navbar-default-color: #8b8d8e;
//$navbar-default-bg: #fff;
$navbar-default-border: #fff;
// Navbar links
//$navbar-default-link-color: #8b8d8e;
//$navbar-default-link-hover-color: #000;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: #000;
$navbar-default-link-active-bg: #fff;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default;
@import '../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss';

html, body {
    overflow-x: hidden;
}

body {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    background: #fff url('../img/bg.jpg') no-repeat center 120px;
    color: #000;
    
}

/* Customize container */
@media (min-width: 768px) {
    .container {
      max-width: 1060px;
    }
  }

  .tags {
      ul.list-inline {
          display: none;
      }
   }

.cursor-default {
    cursor: default !important;
}

.purple {
    background-color: #a05599;
    color: #fff;
}

.yellow {
    background-color: #eedb00;
}

.orange {
    background-color: #ffa100;
}

table {
    &.no-border {
        tr {
            td,
            th {
                border: 0px;
            }
        }
    }
    &.table-condensed {
        tr {
            td {
                border-bottom: 5px solid #fff;
                padding: 3px 5px;
            }
        }
    }
}

.p-l-40 {
    @media (min-width: $screen-md) {
        padding-left: 40px;
    }
}

.p-l-60 {
    @media (min-width: $screen-md) {
        padding-left: 60px;
    }
}

.p-r-40 {
    @media (min-width: $screen-md) {
        padding-right: 40px !important;
    }
}

.blue {
    color: $brand-primary !important;
}

.bold {
    font-weight: bold;
}

img.contact-logo {
    max-width: 170px;
}

.how-reach-label {
    font-weight: bold; //font-size: 18px;
}

@mixin gradient-box() {
    border: 1px solid #d2d2d2;
    background: rgb(237, 237, 237);
    /* Old browsers */
    background: -moz-linear-gradient(left, rgba(237, 237, 237, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(237, 237, 237, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(237, 237, 237, 1) 0%, rgba(255, 255, 255, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: unquote("progid:DXImageTransform.Microsoft.gradient( startColorstr='#ededed', endColorstr='#ffffff',GradientType=1 );");
    /* IE6-9 */
}


.pagination {
    margin-bottom: 5px;
    &>li {
        &.active {
            &>a {
                background-color: #000;
            }
        }
        &.disabled {
            display: none;
        }
        &>a {
            border: 0px;
            color: #8b8d8e;
            padding: 3px 7px;
            margin-left: 3px;
            margin-right: 3px;

            &:hover,
            &:focus {
                color: #fff;
            }
        }
        &:last-of-type,
        &:first-of-type {
            
            a {
                color: #fff;
                text-indent: -9999px;
                background-image: url('../img/pagi-arrow-right.png');
                background-position: center center;
                background-repeat: no-repeat;
                margin-left: 10px;
            }
        }
        &:first-of-type {
            
            a {
                background-image: url('../img/pagi-arrow-left.png');
                margin-right: 10px;
            }
        }
    }
}

.modal-content {
    @include gradient-box;
    
    &.green-bg {
        background: #000 url('../img/form-sent.png') no-repeat -55px -55px;
        .modal-header {
            border: 0px;
        }
        .modal-body {
            padding-bottom: 23px;
        }
    }
    .modal-header {
        .modal-title {
            font-size: 26px;
            font-weight: bold;

            @media (min-width: $screen-lg) {
                margin-left: 10px;
            }
        }
    }
    .modal-body {
        
        table {
            tr {
                th {
                    text-align: right;
                    span {
                        color: #8b8d8e;
                        font-weight: normal;
                    }
                }
            }
        }

        @media (min-width: $screen-lg) {
            padding-right: 50px;
            padding-left: 25px;
        }
    }
}


/* .squaredFour */

.squaredFour {
    width: 15px;
    position: relative;
    margin: 0px auto;
    label {
        width: 15px;
        height: 15px;
        cursor: pointer;
        position: absolute;
        top: 3px;
        left: 0;
        background: #fff; //background: linear-gradient(top, #fcfff4 0%, #dfe5d7 40%, #b3bead 100%);
        border-radius: 0px;
        border: 1px solid #000; //box-shadow: inset 0px 1px 1px white, 0px 1px 3px rgba(0, 0, 0, 0.5);
        &:after {
            content: '';
            width: 9px;
            height: 5px;
            position: absolute;
            top: 3px;
            left: 2px;
            border: 3px solid #000;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
        }
        &:hover::after {
            opacity: 0.5;
        }
    }
    input[type=checkbox] {
        visibility: hidden;
        &:checked+label:after {
            opacity: 1;
        }
    }
}


/* end .squaredFour */

.navbar-default {
    background-color: #000;
    @media (min-width: $screen-sm) {
        background-color: #fff;
    }
    a#logo {
        img {
            margin-top: -25px;
            max-width: 260px;
            /*@media (max-width: 341px) {
                max-width: 200px;
            }*/
            @media (min-width: $screen-lg) {
                margin-left: 10px;
            }
        }
    }
    .navbar-nav {
        &>li {
            &>a {
                text-transform: uppercase;
                color: #fff;
                @media (min-width: $screen-sm) {
                    color: #8b8d8e;
                }

                &:hover {
                    color: #fff;
                    @media (min-width: $screen-sm) {
                        color: #000;
                    }
                }
            }
            &.active {
                &>a {
                    font-weight: bold;
                }
            }
        }
    }
    
}

#best {
    position: relative;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    padding: 10px 10px 10px 10px;
    background: url('../img/best-bg.png') no-repeat left top;
    width: 674px;
    height: 86px;
    display: none;

    /*&:after {
        content: "";
        display: none;
        position: absolute;
        right: -30px;
        top: 0px;
        width: 60px;
        height: 63px;
        transform: skew(-40deg);
        background-color: #000;
        box-shadow: 10px 5px 10px #333;
        @media (min-width: $screen-lg) {
            display: block;
        }
    }*/
    
    @media (min-width: $screen-md) {
        position: absolute;
        left: -5px;
        top: -30px;
        z-index: 1000;
        font-size: 30px;
        padding: 10px 0px 10px 42px;
        display: block;
    }
    
}

#carousel-example-generic {
    display: none;
    .carousel-inner {
        border: 10px solid #fff;
        &>.item {
            &>img {
                margin: 0 auto;
                
            }
            .carousel-caption {
                background-color: $brand-success;
                position: absolute;
                left: 0px;
                bottom: 7%;
                width: 280px;
                padding-right: 20px;
                padding-top: 10px;
                padding-bottom: 10px;
                p {
                    position: relative;
                    z-index: 20;
                    color: #000;
                    text-shadow: none;
                    text-align: right;
                    font-size: 22px;
                    font-style: italic;
                    line-height: 1;
                    &.sign {
                        font-size: 18px;
                        color: #fff;
                        margin: 0px;
                        padding: 0px;
                        font-style: normal;
                    }
                }
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    z-index: 9;
                    left: -75px;
                    top: 0px;
                    width: 150px;
                    height: 100%;
                    transform: skew(40deg);
                    background-color: $brand-success;
                }
                @media (min-width: $screen-lg) {
                    left: 73%;
                }
            }
        }
    }
    .carousel-indicators {
        width: auto;
        margin: 0px;
        top: 20px;
        right: -370px;
        li {
            margin: 0px;
            border: 0px;
            border-radius: 0px;
            background-color: transparent;
            width: 0px;
            height: 0px;
            border-top: 13px solid $brand-success;
            border-left: 13px solid transparent;
            &.active {
                border-top: 13px solid $brand-primary;
            }
        }
    }
    .carousel-control {
        .glyphicon {
            display: none;
        }
        &.right {
            background-image: none;
        }
        &.left {
            background-image: none;
        }
    }
    @media (min-width: $screen-md) {
        display: block;
    }
    @media (min-width: $screen-lg) {
        margin-top: -30px;
    }
}

#search {
    background-color: #fff;
    padding: 10px;
    .green-board {
        background-color: $brand-success;
        font-size: 26px;
        font-weight: 600;
        color: #000;
        padding: 15px 5px 15px 5px;
        text-align: center;
        span {
            color: #fff;
            font-weight: 800;
        }
    }
    .blue-board {
        display: block;
        margin-top: 10px;
        font-size: 26px;
        font-weight: 600;
        color: #fff;
        padding: 20px 5px 20px 5px;
        text-align: center;
    }
    .job {
        margin-top: 10px;
        padding: 10px;
        min-height: 130px;
        @include gradient-box();
        h2 {
            margin: 0px 0px 5px 0px;
            a {
                font-size: 20px;
                font-weight: bold;
            }
        }
        p {
            font-size: 14px;
            color: #000;
        }
        .tags {
            text-align: right;
            @media (min-width: $screen-md) {
                display: table-column;
            }
        }
        ul.list-inline {
            margin: 0px 0px 30px 0px;
            padding: 0px;
            text-align: right;
            li {
                margin: 0px;
                padding: 0px 0px 0px 5px;
                a {
                    padding: 0px;
                    font-size: 11px;
                    color: #8b8d8e;
                    &.tag-purple {
                        border-top: 4px solid #a05599;
                    }
                    &.tag-yellow {
                        border-top: 4px solid #eedb00;
                    }
                    &.tag-orange {
                        border-top: 4px solid #ffa100;
                    }
                }
            }
        }
        .btn-primary {
            text-align: left;
            font-weight: bold;
            font-size: 14px;
            min-width: 120px;
            border: 0px;
            border-radius: 2px;
            .fa {
                padding-left: 30px;
            }
            img {
                margin-left: 30px;
            }
            &:hover,
            &:focus {
                background-color: lighten($brand-primary, 10%);
            }
        }
        &.detail {
            h2 {
                font-size: 18px;
                font-weight: bold;
                a {
                    font-size: 26px;
                }
            }
            ul {
                padding-left: 12px;
            }
            img {
                min-width: 40px;
                @media (max-width: $screen-sm) {
                    margin: 10px auto;
                }
            }
            hr {
                border-color: #dcdfe1;
            }
            .btn-primary {
                img {
                    min-width: auto;
                }
                @media (max-width: $screen-sm) {
                    margin-bottom: 30px;
                }
            }
            @media (min-width: $screen-md) {
                margin-left: 100px;
                margin-right: 100px;
            }
        }
    }
    .content & {
        h1 {
            text-transform: uppercase;
            font-size: 30px;
            font-weight: bold;
            margin-top: 0px;
            margin-left: 8px;
        }
        hr {
            border-color: #8b8d8e;
            margin-top: 0px;
        }
        @media (min-width: $screen-md) {
            margin: -100px 0px 0px 0px;
        }
        @media (min-width: $screen-lg) {
            margin: -100px 30px 0px 30px;
        }
    }
    @media (min-width: $screen-lg) {
        margin: -300px 0px 0px 30px;
    }
}

.green-label {
    position: relative;
    background-color: $brand-success;
    color: #000;
    font-weight: bold;
    font-size: 20px;
    padding: 5px 5px 5px 40px;
    max-width: 90%;
    margin-left: -10px;
    margin-bottom: 20px;
    &:after {
        content: "";
        display: none;
        position: absolute;
        right: -30px;
        top: 0px;
        width: 60px;
        height: 52px;
        transform: skew(-40deg);
        background-color: $brand-success; //box-shadow: 10px 5px 10px #333;
        @media (min-width: $screen-md) {
            display: block;
        }
    }
    @media (min-width: $screen-md) {
        font-size: 30px; //padding: 10px 50px 10px 70px;
    }
}

.about-tabs {
    margin-top: 70px;
    margin-bottom: 30px;
    .nav-tabs {
        border: 0px;
        @media (min-width: $screen-md) {
            display: flex;
            flex-direction: row; //flex-wrap: wrap;
            justify-content: space-between;
        }
        &>li {
            background-color: rgba(221, 234, 240, 0.30);
            margin: 0px 10px;
            border: 0px;
            width: 100%;
            @media (min-width: $screen-md) {
                flex: 1;
                height: 180px;
                line-height: 180px;
                display: inline-block;
            }
            .desc {
                display: none;
                line-height: 1.2;
                font-size: 14px;
                color: #77797a;
                text-align: center;
                padding-top: 10px;
                &.spec {
                    color: #000;
                    display: inline-block;
                    vertical-align: middle;
                    padding: 10px;
                }
                @media (min-width: $screen-md) {
                    display: block;
                }
            }
            @media (min-width: $screen-md) {
                &:before {
                    content: '';
                    display: block;
                    width: 180px;
                    height: 180px;
                    position: absolute;
                    top: 0px;
                    left: 20px;
                    transform: rotate(45deg);
                    background-color: rgba(221, 234, 240, 0.30);
                }
            }
            &>a {
                font-size: 22px;
                font-weight: bold;
                color: #000;
                text-align: center;
                display: inline-block;
                vertical-align: middle;
                width: 100%;
                &:hover, &:focus, &:active {
                    border: 0px;
                    @media (min-width: $screen-md) {
                        background-color: transparent;
                    }
                }
            }
            &.active {
                border: 0px;
                @media (min-width: $screen-md) {
                    .desc:after {
                        content: '';
                        display: block;
                        width: 1px;
                        height: 50px;
                        border-right: 1px solid #d2d2d2;
                        position: absolute;
                        top: 300px;
                        left: 50%;
                    }
                }
                &>a {
                    font-size: 22px;
                    color: #000;
                    border: 0px;
                    @media (min-width: $screen-md) {
                        background-color: transparent;
                    }
                }
            }
        }
    }
    .tab-content {
        @media (min-width: $screen-md) {
            margin-top: 150px;
        }
        @include gradient-box();
        .tab-pane {
            padding: 0px 15px 15px 15px;
            h2 {
                background-color: #d2d2d2;
                margin: 0px -15px 0px -15px;
                padding: 10px;
                font-size: 23px;
                font-weight: bold;
            }
            h3 {
                font-weight: bold;
                color: $brand-primary;
                font-size: 20px;
            }
        }
    }
}

h1.main-head {
    font-weight: bold;
    color: #000;
    font-size: 30px;
    margin-top: 40px;

    @media(min-width: $screen-sm) and (max-width: $screen-md + 1px) {
        margin-top: 160px;
    }
}

ul.main-points {
    margin-top: 20px;
    li {
        list-style: none;
        font-size: 22px;
        font-weight: bold;
        position: relative;
        margin-bottom: 15px;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: -40px;
            width: 0;
            height: 0;
            border-bottom: 25px solid $brand-primary;
            border-left: 25px solid transparent;
        }
    }
}

ul.second-points {
    margin-top: 0px;
    li {
        list-style: none;
        position: relative;
        margin-bottom: 15px;
        
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0px;
            left: -30px;
            width: 0;
            height: 0;
            border-bottom: 15px solid $brand-primary;
            border-left: 15px solid transparent;
        }
    }
}

.banner-black {
    display: block;
    background-color: #000;
    padding: 25px;
    color: #fff;
    margin-top: 15px;
    margin-bottom: 15px;
    img {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    h3 {
        font-weight: bold;
        font-size: 25px;
        margin-top: 0px;
    }
    p {
        font-size: 19px;
    }
    .text-primary {
        font-size: 16px;
    }
    &:hover {
        color: #fff;
        text-decoration: none;
    }
}

footer {
    margin-top: 15px;
    margin-bottom: 30px;
    h3 {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: normal;
        color: #8b8d8e;
    }
    .banner-shadow {
        background-color: #e5e5e5;
        padding: 15px;
        img {
            max-width: 180px;
            @media(min-width: $screen-sm) {
                margin-left: -43px;
            }
        }
        p {
            font-size: 15px;
        }
        iframe.embed-responsive-item {
            max-height: 300px;
        }
        @media(min-width: $screen-md) {
            height: 330px;
        }
    }
}